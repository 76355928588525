import { CssBaseline, Typography } from '@mui/material';
import './App.css';
import { Login } from './Login';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthProvider } from './Auth';
import { Home } from './Home';

function App() {
  return (
    <CssBaseline>
      <AuthProvider>
        <div className="AppHeader">
          <Typography>Intertwine</Typography>
        </div>
        <Router>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
        </Router>
      </AuthProvider>
    </CssBaseline>
  );
}

export default App;
