import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react"
import styled from "@emotion/styled";

export interface ActivityItemProps extends React.HTMLAttributes<HTMLDivElement> {
    id: string;
    title: string;
    onDelete: (id: string) => void;
    onComplete: (id: string) => void;
}

export function ActivityItem({ className, id, title, onDelete, onComplete }: ActivityItemProps): JSX.Element {
    return (
        <Accordion className={className}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="#424242">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <OutlinedButton variant="outlined" onClick={() => onDelete(id)}>Delete</OutlinedButton>
                <ColoredButton variant="contained" onClick={() => onComplete(id)}>Complete</ColoredButton>
            </AccordionDetails>
        </Accordion>
    );
}

const OutlinedButton = styled(Button)`
  color: #0097A7;
  border-color: #0097A7;
  margin-right: 16px;
  &:hover {
    color: #0097A7;
    border-color: #0097A7;
  }
`;

const ColoredButton = styled(Button)`
  color: white;
  background-color: #0097A7;
  &:hover {
    color: white;
    background-color: #0097A7;
  }
`;