import { Board } from "./Board";
import { IntertwineUser } from "./User";
import { UserAvatar } from "./UserAvatar";

interface FriendsBoardProps {
    friends: IntertwineUser[];
}

export function FriendsBoard({ friends }: FriendsBoardProps): JSX.Element {
    return (
        <Board title="Friends">
            {friends.map((friends) => <div style={{ margin: '0 8px' }}><UserAvatar user={friends} /></div>)}
        </Board>
    )
}