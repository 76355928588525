import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import React from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";
import GoogleButton from 'react-google-button'
import { Typography } from "@mui/material";

function loginWithGoogle(): void {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential?.accessToken;
            const user = result.user;
            console.log(`Logged in as ${JSON.stringify(user)} with token ${JSON.stringify(token)}`);
        }).catch(console.error);
};

export function Login(): JSX.Element {
    const { currentUser } = React.useContext(AuthContext);

    if (currentUser) {
        return <Redirect to={"/"} />;
    }

    if (currentUser === undefined) {
        return <Typography variant="body1">Loading...</Typography>
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant="h2">Welcome to Intertwine!</Typography>
            <GoogleButton onClick={loginWithGoogle}>Log in</GoogleButton>
        </div>
    );
}