import React from "react";
import { ActivityController } from "./ActivityController";
import { listUsers } from "./client";
import { FriendsBoard } from "./FriendsBoard";
import { IdeasBoard } from "./IdeasBoard";
import { IntertwineUser } from "./User";

export function Home(): JSX.Element {
    const [users, setUsers] = React.useState<IntertwineUser[]>([]);

    React.useEffect(() => {
        let shouldCancel = false;
        listUsers().then((listOfUsers) => {
            if (shouldCancel) {
                return;
            }
            setUsers(listOfUsers);
        });
        return () => {
            shouldCancel = true;
        }
    }, []);

    return (
        <>
            <FriendsBoard friends={users} />
            <IdeasBoard activityGroups={[
                {
                    title: 'Food and Drinks',
                    details: '13 activities',
                },
                {
                    title: 'Outdoors',
                    details: '8 activities',
                },
                {
                    title: 'Games',
                    details: '19 activities',
                },
            ]} />
            <ActivityController />
        </>
    );
}