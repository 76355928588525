import styled from "@emotion/styled";
import React from "react";
import { ActivityItem } from "./ActivityItem";
import { Activity } from "./client";

export interface ActivitiesProps {
    activities: Activity[];
    onCompleteActivity: (id: string) => void;
    onDeleteActivity: (id: string) => void;
}

export function Activities({ activities, onCompleteActivity, onDeleteActivity }: ActivitiesProps): JSX.Element {
    return (
        <ActivityList>
          {activities.map((activity) => 
            <SpacedActivityItem 
              key={activity.title} 
              id={activity.id} 
              title={activity.title}
              onDelete={onDeleteActivity}
              onComplete={onCompleteActivity}
            ></SpacedActivityItem>
          )}
        </ActivityList>
    );
}

const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpacedActivityItem = styled(ActivityItem)`
  margin-bottom: 16px;
`;