import { User } from "@firebase/auth";
import { v4 as uuidv4 } from 'uuid';

export interface IntertwineUser {
    id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl: string;
    firebaseUserId?: string;
}

export function createUserFromAuthUser(authUser: User): IntertwineUser {
    const [firstName, lastName] = authUser.displayName!.split(' ');
    return {
        id: uuidv4(),
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        profilePictureUrl: authUser.photoURL ?? "",
        firebaseUserId: authUser.uid,
    }
}