import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { IntertwineUser } from "./User";

interface UserAvatarProps {
    user: IntertwineUser;
}

export function UserAvatar({ user }: UserAvatarProps): JSX.Element {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <AvatarThumbnail src={user.profilePictureUrl} alt="" />
        </div>
    )
}

const AvatarThumbnail = styled('img')`
    margin-right: 16px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    box-shadow: 0px 5px 16px;
`