import { Card, CardContent, CardMedia, Typography } from "@mui/material"
import { CSSProperties } from "react"
import { ActivityGroup } from "./ActivityGroup"

interface ActivityGroupCardProps {
    activityGroup: ActivityGroup;
    style?: CSSProperties;
}

export function ActivityGroupCard({ activityGroup, style }: ActivityGroupCardProps): JSX.Element {
    return (
        <Card sx={{ width: 345 }} style={style}>
            <CardMedia component="img" height="140" image="/placeholder.jpeg" />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {activityGroup.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {activityGroup.details}
                </Typography>
            </CardContent>
        </Card>
    )
}