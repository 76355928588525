import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ReactChild, ReactNode } from "react";

interface BoardProps {
    title: string;
    Icon?: ReactChild;
    children: ReactNode;
}

export function Board({ title, Icon, children }: BoardProps): JSX.Element {
    return (
        <Container>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ color: "#313E26", flexGrow: 1 }} variant="body1">{title}</Typography>
                {Icon && Icon}
            </div>
            <Body>
                {children}
            </Body>
        </Container>
    );
}

const Container = styled("div")`
    background-color: #F3F3F34A;
    border-radius: 8px;
    padding: 32px;
`;

const Body = styled("div")`
    margin-top: 16px;
    display: flex;
    flex-flow: row wrap; 
`