import { getAuth, onAuthStateChanged, setPersistence, User } from "@firebase/auth";
import React, { useEffect } from "react";
import { login } from "./client";
import { createUserFromAuthUser, IntertwineUser } from "./User";

export const AuthContext = React.createContext<{ currentUser?: IntertwineUser | null }>({});

interface AuthProviderProps {
    children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
    const [currentUser, setCurrentUser] = React.useState<IntertwineUser | null>();

    useEffect(() => {
        const auth = getAuth();
        setPersistence(auth, { type: 'LOCAL' })
        onAuthStateChanged(auth, (user) => {
            if (user === null) {
                setCurrentUser(null);
            } else {
                const intertwineUser = createUserFromAuthUser(user);
                login(intertwineUser).then(() => setCurrentUser(intertwineUser)).catch(console.error);
            }
        });
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser }}>
            { children }
        </AuthContext.Provider>
    );
}