import axios from 'axios';
import { ActivityGroup } from './ActivityGroup';
import { IntertwineUser } from './User';

export interface Activity {
    id: string;
    title: string;
    completed: boolean;
}

const httpClient = axios.create({
    // baseURL: 'http://localhost:8080',
    baseURL: 'https://app-bzyen5kj5q-uc.a.run.app',
});

export async function createActivityGroup(activityGroup: ActivityGroup): Promise<void> {
    return httpClient.post('activityGroup', activityGroup);
}

export async function listActivities(): Promise<Activity[]> {
    const response = await httpClient.get('activities');
    return response.data;
}

export async function createActivity(activity: Activity): Promise<void> {
    return httpClient.post('activities', activity);
}

export async function deleteActivity(id: string): Promise<void> {
    return httpClient.delete(`activities/${id}`);
}

export async function completeActivity(id: string): Promise<void> {
    return httpClient.post('activities:complete', { id });
}

export async function listUsers(): Promise<IntertwineUser[]> {
    const response = await httpClient.get('users');
    return response.data;
}

export async function login(user: IntertwineUser): Promise<void> {
    return httpClient.post('users', user);
}