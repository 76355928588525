import styled from "@emotion/styled";
import { Add } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import React from "react";
import { ActivityGroup } from "./ActivityGroup";
import { ActivityGroupCard } from "./ActivityGroupCard";
import { Board } from "./Board";

interface IdeasBoardProps {
    activityGroups: ActivityGroup[];
}

export function IdeasBoard({ activityGroups }: IdeasBoardProps): JSX.Element {
    const [open, setOpen] = React.useState(false);

    const onClickAdd = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Board title="Ideas" Icon={<IconButton onClick={onClickAdd}><Add /></IconButton>}>
                    {activityGroups.map((activityGroup) => <ActivityGroupCard style={{ margin: "0 16px 16px 0" }} activityGroup={activityGroup} />) }
            </Board>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create a new group</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Group name"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const Container = styled("div")`
    background-color: #F3F3F34A;
    border-radius: 8px;
    padding: 32px;
`;

const Body = styled("div")`
    margin-top: 16px;
`