import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import styled from '@emotion/styled';
import React from 'react';
import { Activity, completeActivity, createActivity, deleteActivity, listActivities } from './client';
import { v4 as uuidv4 } from 'uuid';
import { Activities } from './Activities';
import { AuthContext } from './Auth';
import { Redirect } from 'react-router-dom';

export function ActivityController(): JSX.Element {
    const { currentUser } = React.useContext(AuthContext);

    const [activities, setActivities] = React.useState<Activity[]>([]);
    const [tempActivityTitle, setTempActivityTitle] = React.useState<string>();
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (currentUser) {
            listActivities().then((allActivities) => setActivities(allActivities));
        }
    }, [currentUser])

    if (currentUser === undefined) {
        return <Redirect to={"/login"} />
    }

    const handleAdd = () => {
        if (tempActivityTitle === undefined || tempActivityTitle.length === 0) {
        setOpen(false);
        return;
        }
        const newActivity = { id: uuidv4(), title: tempActivityTitle, completed: false };
        createActivity(newActivity).then(() => {
        setActivities((prev) => [...prev, newActivity])
        setOpen(false);
        });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const onDeleteActivity = (id: string) => {
        deleteActivity(id).then(() => setActivities((prev) => prev.filter((activity) => activity.id !== id)));
    };

    const onCompleteActivity = (id: string) => {
        completeActivity(id).then(() => setActivities((prev) => prev.filter((activity) => activity.id !== id)));
    };
    
    return (
        <>
            <div className="App">
                <Activities activities={activities} onCompleteActivity={onCompleteActivity} onDeleteActivity={onDeleteActivity} />
                <AddRow>
                <ColoredButton color="primary" aria-label="add an activity" onClick={() => {setOpen(true)}}>
                    <AddCircleOutline />
                </ColoredButton>
                </AddRow>
            </div>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Add a new activity</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Activity"
                    fullWidth
                    variant="standard"
                    onChange={(event) => {
                    setTempActivityTitle(event.target.value);
                    }}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd}>Add</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const AddRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const ColoredButton = styled(IconButton)`
  color: #744646;
  margin-top: 16px;
  transform: scale(1.4);
`;
